import { v4 as uuid } from "uuid"
import { Puzzle } from "../../types/Puzzle"

const puzzleList: Puzzle[] = [
  {
    id: uuid(),
    category: "Phrase",
    value: "DEEZ NUTS",
  },
  {
    id: uuid(),
    category: "Phrase",
    value: "I GOT MY FISH",
  },
  { id: uuid(), category: "Phrase", value: "TAKE YOUR TIME" },
  { id: uuid(), category: "Phrase", value: "CATCHING SOME RAYS" },
  { id: uuid(), category: "Phrase", value: "EBB AND FLOW" },
  { id: uuid(), category: "Phrase", value: "IT TAKES TWO TO TANGO" },
  { id: uuid(), category: "Phrase", value: "BLURT IT OUT" },
  {
    id: uuid(),
    category: "Phrase",
    value: "WORKING HARD OR HARDLY WORKING?",
  },
  { id: uuid(), category: "Phrase", value: "FLUENT IN SPANISH" },
  { id: uuid(), category: "Phrase", value: "HAVE FAITH" },
  { id: uuid(), category: "Phrase", value: "DROP EVERYTHING" },
  { id: uuid(), category: "Phrase", value: "SAY IT ISN'T SO" },
  { id: uuid(), category: "Phrase", value: "LET THE GAMES BEGIN" },
  { id: uuid(), category: "Phrase", value: "SAY THE MAGIC WORD" },
  { id: uuid(), category: "Phrase", value: "OFF TO A FAST START" },
  {
    id: uuid(),
    category: "Phrase",
    value: "TODAY IS THE FIRST DAY OF THE REST OF YOUR LIFE",
  },
  { id: uuid(), category: "Phrase", value: "IT'S A VERY LONG STORY" },
  { id: uuid(), category: "Phrase", value: "STRAIGHT TO THE TOP" },
  { id: uuid(), category: "Phrase", value: "NO DOUBT ABOUT IT" },
  { id: uuid(), category: "Phrase", value: "FADING INTO THE SUNSET" },
  { id: uuid(), category: "Phrase", value: "TAKE A WHIFF" },
  { id: uuid(), category: "Phrase", value: "WORTH EVERY PENNY" },
  { id: uuid(), category: "Phrase", value: "IT WAS A FLUKE" },
  {
    id: uuid(),
    category: "Phrase",
    value: "MY EYES ARE BIGGER THAN MY STOMACH",
  },
  { id: uuid(), category: "Phrase", value: "IT'LL BE OUR LITTLE SECRET" },
  { id: uuid(), category: "Phrase", value: "COMMITMENT TO EXCELLENCE" },
  { id: uuid(), category: "Phrase", value: "PROBLEM SOLVES" },
  {
    id: uuid(),
    category: "Phrase",
    value: "WOULD YOU LIKE FRIES WITH THAT?",
  },
  { id: uuid(), category: "Phrase", value: "HEAVE-HO" },
  { id: uuid(), category: "Phrase", value: "RELATED BY MARRIAGE" },
  { id: uuid(), category: "Phrase", value: "WAFER-THIN" },
  {
    id: uuid(),
    category: "Phrase",
    value: "AVAILABLE WHEREVER BOOKS ARE SOLD",
  },
  { id: uuid(), category: "Phrase", value: "IN MY HUMBLE OPINION" },
  { id: uuid(), category: "Phrase", value: "KEEP UP THE GOOD WORK" },
  { id: uuid(), category: "Phrase", value: "BROADEN YOUR HORIZONS" },
  { id: uuid(), category: "Phrase", value: "STANDING TALL" },
  { id: uuid(), category: "Phrase", value: "SHOCK VALUE" },
  { id: uuid(), category: "Phrase", value: "FIRST IN THE CLASS" },
  { id: uuid(), category: "Phrase", value: "RIDING SHOTGUN" },
  { id: uuid(), category: "Phrase", value: "SHOWING OFF" },
  {
    id: uuid(),
    category: "Phrase",
    value: "YOU LEARN SOMETHING NEW EVERY DAY",
  },
  { id: uuid(), category: "Phrase", value: "NOT A CLOUD IN THE SKY" },
  { id: uuid(), category: "Phrase", value: "THICK AS THEIVES" },
  { id: uuid(), category: "Phrase", value: "HOW LUCKY" },
  { id: uuid(), category: "Phrase", value: "SEEMS LIKE OLD TIMES" },
  {
    id: uuid(),
    category: "Phrase",
    value: "WE FINISH EACH OTHER'S SENTENCES",
  },
  { id: uuid(), category: "Phrase", value: "TALL IN THE SADDLE" },
  { id: uuid(), category: "Phrase", value: "BOYISH CHARM" },
  { id: uuid(), category: "Phrase", value: "WE'RE ALL IN THE SAME BOAT" },
  { id: uuid(), category: "Phrase", value: "DON'T MESS WITH TEXAS" },
  { id: uuid(), category: "Phrase", value: "HOLD YOUR HORSES" },
  { id: uuid(), category: "Phrase", value: "STAR POWER" },
  { id: uuid(), category: "Phrase", value: "BIGGER IS BETTER" },
  { id: uuid(), category: "Phrase", value: "A NICE CHANGE OF PACE" },
  { id: uuid(), category: "Phrase", value: "JUST FOR THE FUN OF IT" },
  { id: uuid(), category: "Phrase", value: "TOUGH BUT FAIR" },
  { id: uuid(), category: "Phrase", value: "RIDE 'EM COWBOY" },
  { id: uuid(), category: "Phrase", value: "GIVE ME A HUG" },
  { id: uuid(), category: "Phrase", value: "IN THE SWIM OF THINGS" },
  { id: uuid(), category: "Phrase", value: "CUT FROM THE SAME CLOTH" },
  { id: uuid(), category: "Phrase", value: "NO STRINGS ATTACHED" },
  { id: uuid(), category: "Phrase", value: "WE GO WAY BACK" },
  { id: uuid(), category: "Phrase", value: "LIKE NEVER BEFORE" },
  { id: uuid(), category: "Phrase", value: "ABOUT THE AUTHOR" },
  { id: uuid(), category: "Phrase", value: "IN A HUFF" },
  { id: uuid(), category: "Phrase", value: "VETO POWER" },
  { id: uuid(), category: "Phrase", value: "FINISH WHAT YOU'VE STARTED" },
  { id: uuid(), category: "Phrase", value: "BEING COY" },
  { id: uuid(), category: "Phrase", value: "SETTING THE WORLD ON FIRE" },
  { id: uuid(), category: "Phrase", value: "FOR WHAT IT'S WORTH" },
  { id: uuid(), category: "Phrase", value: "YOU'RE FULL OF SURPRISES" },
  { id: uuid(), category: "Phrase", value: "HEAVEN FORBID" },
  {
    id: uuid(),
    category: "Phrase",
    value: "THE WAY TO A MAN'S HEART IS THROUGH HIS STOMACH",
  },
  { id: uuid(), category: "Phrase", value: "WARM AND FUZZY" },
  {
    id: uuid(),
    category: "Phrase",
    value: "AND THEY LIVED HAPPILY EVER AFTER",
  },
  { id: uuid(), category: "Phrase", value: "YOU'VE GROWN UP" },
  { id: uuid(), category: "Phrase", value: "FUN FOR THE WHOLE FAMILY" },
  { id: uuid(), category: "Phrase", value: "VOUCH FOR ME" },
  { id: uuid(), category: "Phrase", value: "BUT WAIT THERE'S MORE" },
  { id: uuid(), category: "Phrase", value: "TWO OF A KIND" },
  {
    id: uuid(),
    category: "Phrase",
    value: "YOU CAN'T GET THERE FROM HERE",
  },
  { id: uuid(), category: "Phrase", value: "CRYING WOLF" },
  {
    id: uuid(),
    category: "Phrase",
    value: "WE PASS THE SAVINGS ON TO YOU",
  },
  {
    id: uuid(),
    category: "Phrase",
    value: "IF IT'S NOT ONE THING IT'S ANOTHER",
  },
  { id: uuid(), category: "Phrase", value: "CUTE AS A BUTTON" },
  { id: uuid(), category: "Phrase", value: "HOT AND HUMID" },
  {
    id: uuid(),
    category: "Phrase",
    value: "YOU HAVE YOUR WORK CUT OUT FOR YOU",
  },
  { id: uuid(), category: "Phrase", value: "WORD OF MOUTH" },
  { id: uuid(), category: "Phrase", value: "THE ALOHA STATE" },
  { id: uuid(), category: "Phrase", value: "A FEAST FOR THE SENSES" },
  { id: uuid(), category: "Phrase", value: "BATTERIES NOT INCLUDED" },
  { id: uuid(), category: "Phrase", value: "DRIVE SAFELY" },
  { id: uuid(), category: "Phrase", value: "OFF THE BEATEN PATH" },
  { id: uuid(), category: "Phrase", value: "KEEP THE HOME FIRES BURNING" },
  {
    id: uuid(),
    category: "Phrase",
    value: "ACTIONS SPEAK LOUDER THAN WORDS",
  },
  {
    id: uuid(),
    category: "Phrase",
    value: "IT JUST KEEPS GETTING BETTER AND BETTER",
  },
  { id: uuid(), category: "Phrase", value: "I APPROVE" },
  { id: uuid(), category: "Phrase", value: "ON THE HALF-HOUR" },
  { id: uuid(), category: "Phrase", value: "NO PURCHASE NECESSARY" },
  { id: uuid(), category: "Phrase", value: "TAKING CARE OF BUSINESS" },
  { id: uuid(), category: "Phrase", value: "GIVE ME A BREAK" },
  { id: uuid(), category: "Phrase", value: "IN THE DRIVER'S SEAT" },
  { id: uuid(), category: "Phrase", value: "WISH YOU WERE HERE" },
  { id: uuid(), category: "Phrase", value: "THAT REMAINS TO BE SEEN" },
  { id: uuid(), category: "Phrase", value: "BUSINESS OR PLEASURE" },
  { id: uuid(), category: "Phrase", value: "WIT & WISDOM" },
  { id: uuid(), category: "Phrase", value: "SPREAD YOUR WINGS AND FLY" },
  { id: uuid(), category: "Phrase", value: "LOOK SHARP" },
  {
    id: uuid(),
    category: "Phrase",
    value: "SOME PEOPLE HAVE ALL THE LUCK",
  },
  { id: uuid(), category: "Phrase", value: "POKING FUN" },
  { id: uuid(), category: "Phrase", value: "KNOWN THE WORLD OVER" },
  { id: uuid(), category: "Phrase", value: "BULLETIN BOARD" },
  { id: uuid(), category: "Phrase", value: "WORK OUT THE KINKS" },
  { id: uuid(), category: "Phrase", value: "GOOD POINT" },
  { id: uuid(), category: "Phrase", value: "DEAD GIVEAWAY" },
  {
    id: uuid(),
    category: "Phrase",
    value: "GIVE CREDIT WHERE CREDIT IS DUE",
  },
  { id: uuid(), category: "Phrase", value: "SHOW ME HOW" },
  { id: uuid(), category: "Phrase", value: "OLD ENOUGH TO KNOW BETTER" },
  { id: uuid(), category: "Phrase", value: "SMOOTH SAILING AHEAD" },
  { id: uuid(), category: "Phrase", value: "A MOMENT IN THE SUN" },
  { id: uuid(), category: "Phrase", value: "I GOT MY WISH" },
  { id: uuid(), category: "Phrase", value: "STANDING ROOM ONLY" },
  { id: uuid(), category: "Phrase", value: "TAKE THE BULL BY THE HORNS" },
  { id: uuid(), category: "Phrase", value: "JUST LUCKY I GUESS" },
  { id: uuid(), category: "Phrase", value: "TOUGH IT OUT" },
  {
    id: uuid(),
    category: "Phrase",
    value: "TIME FLIES WHEN YOU'RE HAVING FUN",
  },
  { id: uuid(), category: "Phrase", value: "IN PERFECT HARMONY" },
  { id: uuid(), category: "Phrase", value: "NOTIFY ME" },
  { id: uuid(), category: "Phrase", value: "MOST LIKELY TO SUCCEED" },
  { id: uuid(), category: "Phrase", value: "YOU BE THE JUDGE" },
  { id: uuid(), category: "Phrase", value: "NOT JUST ANOTHER PRETTY FACE" },
  {
    id: uuid(),
    category: "Phrase",
    value: "YOU NEVER KNOW WHERE LIFE WILL TAKE YOU",
  },
  {
    id: uuid(),
    category: "Phrase",
    value: "I THINK YOU'LL BE PLEASANTLY SURPRISED",
  },
  { id: uuid(), category: "Phrase", value: "WHERE THE HEART IS" },
  {
    id: uuid(),
    category: "Phrase",
    value: "GETTING THERE IS HALF THE FUN",
  },
  { id: uuid(), category: "Phrase", value: "FROM SOUP TO NUTS" },
  { id: uuid(), category: "Phrase", value: "JUMPING FOR JOY" },
  { id: uuid(), category: "Phrase", value: "PLEASE VISIT OUR WEBSITE" },
  { id: uuid(), category: "Phrase", value: "IT'S ALL THE SAME" },
  { id: uuid(), category: "Phrase", value: "I'LL TAKE IT FROM HERE" },
  { id: uuid(), category: "Phrase", value: "IN VOUGE" },
  { id: uuid(), category: "Phrase", value: "THE WORLD IS YOUR OYSTER" },
  { id: uuid(), category: "Phrase", value: "PAYING DUES" },
  { id: uuid(), category: "Phrase", value: "HIGHLY ORGANIZED" },
  { id: uuid(), category: "Phrase", value: "LIKE A BREATH OF FRESH AIR" },
  { id: uuid(), category: "Phrase", value: "OUT OF THE CLEAR BLUE SKY" },
  { id: uuid(), category: "Phrase", value: "REVVING UP" },
  {
    id: uuid(),
    category: "Phrase",
    value: "RAISE YOUR RIGHT HAND AND REPEAT AFTER ME",
  },
  { id: uuid(), category: "Phrase", value: "I'LL TAKE YOUR WORD FOR IT" },
  { id: uuid(), category: "Phrase", value: "I'M OVERJOYED" },
  { id: uuid(), category: "Phrase", value: "BAKED OR FRIED" },
  { id: uuid(), category: "Phrase", value: "ALL THINGS CONSIDERED" },
  { id: uuid(), category: "Phrase", value: "STANDING THE TEST OF TIME" },
  { id: uuid(), category: "Phrase", value: "WIGGLE ROOM" },
  { id: uuid(), category: "Phrase", value: "LIVE AND LEARN" },
  { id: uuid(), category: "Phrase", value: "PAINTED INTO A CORNER" },
  {
    id: uuid(),
    category: "Phrase",
    value: "LIKE COMPARING APPLES TO ORANGES",
  },
  {
    id: uuid(),
    category: "Phrase",
    value: "LET YOUR IMAGINATION RUN WILD",
  },
  { id: uuid(), category: "Phrase", value: "I KNOW IT BY HEART" },
  { id: uuid(), category: "Phrase", value: "FIRST COME FIRST SERVED" },
  { id: uuid(), category: "Phrase", value: "A HERO'S WELCOME" },
  { id: uuid(), category: "Phrase", value: "WORDS FAIL ME" },
  { id: uuid(), category: "Phrase", value: "IN THE SPIRIT OF COMPETITION" },
  { id: uuid(), category: "Phrase", value: "HIGH TIME" },
  { id: uuid(), category: "Phrase", value: "NOBODY KNOWS" },
  {
    id: uuid(),
    category: "Phrase",
    value: "WAITING FOR THE OTHER SHOE TO DROP",
  },
  { id: uuid(), category: "Phrase", value: "PIPING HOT" },
  { id: uuid(), category: "Phrase", value: "LIVING PROOF" },
  { id: uuid(), category: "Phrase", value: "ROME WASN'T BUILT IN A DAY" },
  { id: uuid(), category: "Phrase", value: "PULLING OUT ALL THE STOPS" },
  { id: uuid(), category: "Phrase", value: "COOL AS A CUCUMBER" },
  {
    id: uuid(),
    category: "Phrase",
    value: "I'VE GOT A GOOD FEELING ABOUT THIS",
  },
  { id: uuid(), category: "Phrase", value: "OFF TO SEE THE WORLD" },
  { id: uuid(), category: "Phrase", value: "OBEY THE LAW" },
  { id: uuid(), category: "Phrase", value: "TAKE A PENNY LEAVE A PENNY" },
  { id: uuid(), category: "Phrase", value: "BACK AND FORTH" },
  { id: uuid(), category: "Phrase", value: "HUMBLE PIE" },
  { id: uuid(), category: "Phrase", value: "YOU'LL THANK ME LATER" },
  { id: uuid(), category: "Phrase", value: "DON'T QUIT" },
  { id: uuid(), category: "Phrase", value: "RESIST THE TEMPTATION" },
  { id: uuid(), category: "Phrase", value: "IT'S NEITHER HERE NOR THERE" },
  { id: uuid(), category: "Phrase", value: "LIKE THERE'S NO TOMORROW" },
  { id: uuid(), category: "Phrase", value: "THAT'S A GREAT IDEA" },
  {
    id: uuid(),
    category: "Phrase",
    value: "OUT WITH THE OLD IN WITH THE NEW",
  },
  { id: uuid(), category: "Phrase", value: "LUCKY YOU" },
  { id: uuid(), category: "Phrase", value: "I'M HUNGRY" },
  { id: uuid(), category: "Phrase", value: "DON'T SELL YOURSELF SHORT" },
  { id: uuid(), category: "Phrase", value: "BUYING IN BULK" },
  { id: uuid(), category: "Phrase", value: "LIKE A KID IN A CANDY STORE" },
  { id: uuid(), category: "Phrase", value: "KISS IT AND MAKE IT BETTER" },
  { id: uuid(), category: "Phrase", value: "SIT UP STRAIGHT" },
  { id: uuid(), category: "Phrase", value: "LEAVE YOUR CARES BEHIND" },
  { id: uuid(), category: "Phrase", value: "RICH IN HISTORY AND CULTURE" },
  { id: uuid(), category: "Phrase", value: "WAY OF LIFE" },
  { id: uuid(), category: "Phrase", value: "DAWN TO DUSK" },
  {
    id: uuid(),
    category: "Phrase",
    value: "IT JUST DOESN'T GET ANY BETTER THAN THIS",
  },
  { id: uuid(), category: "Phrase", value: "EARN YOUR STRIPES" },
  { id: uuid(), category: "Phrase", value: "GETTING SOME R AND R" },
  { id: uuid(), category: "Phrase", value: "BEST OF THE BEST" },
  { id: uuid(), category: "Phrase", value: "COME WHAT MAY" },
  { id: uuid(), category: "Phrase", value: "NOSE TO THE GRINDSTONE" },
  { id: uuid(), category: "Phrase", value: "I'M BAFFLED" },
  { id: uuid(), category: "Phrase", value: "STEALING THE SPOTLIGHT" },
  { id: uuid(), category: "Phrase", value: "ACTING ON IMPULSE" },
  { id: uuid(), category: "Phrase", value: "WALKING ON EGGSHELLS" },
  { id: uuid(), category: "Phrase", value: "MAKING THE GRADE" },
  {
    id: uuid(),
    category: "Phrase",
    value: "COME OUT COME OUT WHEREVER YOU ARE",
  },
  { id: uuid(), category: "Phrase", value: "FAIR VALUE" },
  { id: uuid(), category: "Phrase", value: "I'VE LEARNED MY LESSON" },
  { id: uuid(), category: "Phrase", value: "I WOULDN'T CHANGE A THING" },
  { id: uuid(), category: "Phrase", value: "KICK THE HABIT" },
  { id: uuid(), category: "Phrase", value: "CLASS DISMISSED" },
  { id: uuid(), category: "Phrase", value: "BUMPER CROP" },
  { id: uuid(), category: "Phrase", value: "YOU CAN'T MAKE THIS STUFF UP" },
  { id: uuid(), category: "Phrase", value: "I WONDER WHY" },
  { id: uuid(), category: "Phrase", value: "REWRITING THE RECORD BOOKS" },
  {
    id: uuid(),
    category: "Phrase",
    value: "BRIMMING WITH OLD-WORLD CHARM",
  },
  { id: uuid(), category: "Phrase", value: "BREAK IT OFF" },
]
export default puzzleList
